import React from "react"
import "./contactus.sass"
import Reaptcha from 'reaptcha';
import loadingImg from "../../../static/images/loading.gif"


class ContactUs extends React.Component{

  state = {
    form: {
      nombre: {value:"", isValid:false, isEmpty:false},
      correo: {value:"", isValid:false, isEmpty:false},
      notas: {value:"", isValid:false, isEmpty:false},
    },
    isFormValid: false,
    loading: false,
    done: false,
    surname: 'Deja este espacio en blanco',
    address: 'Deja este espacio en blanco',
    captcha: false
  }

  handleInputChange = event => {
    const inputName = event.target.name
    const inputValue = event.target.value
    const inputType = event.target.type
    const form = this.state.form

    form[inputName].value = inputValue
    form[inputName].isValid = this.isInputValid(inputValue,inputType)
    form[inputName].isEmpty = this.isInputEmpty(inputValue)

    const isFormValid = this.isformValid(this.state.form,this.state.captcha)
    this.setState({form: form, isFormValid: isFormValid})
  }

  isInputValid(inputValue,inputType){
    if(inputValue.trim()){
      if(inputType==="email"){
        const hasAnAtOnEmail = inputValue.search("@")>0
        return hasAnAtOnEmail
      }
      else{
        return true
      }
    }
    else{
      return false
    }
  }

  sendEmail = () => {
    if(this.state.isFormValid){
      this.setState({loading:true})

      fetch('https://vj0jnj7x37.execute-api.us-west-2.amazonaws.com/default/Send_Mail_HoneyPot',
        {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify({
            name: this.state.form.nombre.value,
            email: this.state.form.correo.value,
            message: this.state.form.notas.value,
            surname: 'Deja este espacio en blanco',
            address: 'Deja este espacio en blanco',
            title: 'Información del contacto',
            toAddress: 'fpgm14@yahoo.es',
          }),
        }).then((response) =>{
          this.setState({
            done: true,
            loading: false
          });
        })
    }
  }

  isformValid(form,isCaptchaSucessful){
    let isValid = true
    for (let key in form) {
      isValid = isValid && form[key].isValid
    }
    isValid = isValid && isCaptchaSucessful
    return isValid
  }

  isInputEmpty(inputValue){
    if(inputValue.trim()){
      return true
    }
    else{
      return false
    }
  }

  onVerify = recaptchaResponse => {
    const form = this.state.form
    const isFormValid = this.isformValid(form,true)
    this.setState({isFormValid: isFormValid, captcha: true})
  };

  showForm(){
    return !this.state.loading && !this.state.done
  }

  showLoading(){
    return this.state.loading && !this.state.done
  }

  showDone(){
    return !this.state.loading && this.state.done
  }

  render (){
    return (
      <div className="contactus">
        <div className="contactus__title-wrap">
          <div className="contactus__alert">
            <p className="contactus__alert-important">!</p>
            <p> Recuerda usar este formulario para preguntar respecto a nuestros servicios. No se responderán consultas médicas por este medio.</p>
          </div>
          <h3 className="contactus__title">Contáctanos</h3>
        </div>
        <div className={this.showForm()?"contactus__form-container":"contactus__display-none"}>
          <form onSubmit={this.handleSubmit}>
            <div className="contactus__input-wrap">
              <input
                className={this.state.form.nombre.isEmpty?"contactus__input contactus__input-valid":"contactus__input"}
                type="text"
                name="nombre"
                value={this.state.form.nombre.value}
                onChange={this.handleInputChange}
                required
              >
              </input>
              <label className={this.state.form.nombre.isEmpty?"contactus__label contactus__label-valid":"contactus__label"}>Nombre</label>
            </div>
            <div className="contactus__input-wrap">
              <input
                className={this.state.form.correo.isEmpty?"contactus__input contactus__input-valid":"contactus__input"}
                type="email"
                name="correo"
                value={this.state.form.correo.value}
                onChange={this.handleInputChange}
                required
              ></input>
              <label className={this.state.form.correo.isEmpty?"contactus__label contactus__label-valid":"contactus__label"}>Correo</label>
            </div>
            <div className="contactus__input-wrap">
              <textarea
                className={this.state.form.notas.isEmpty?"contactus__input contactus__input-valid":"contactus__input"}
                type="text"
                name="notas"
                value={this.state.form.notas.value}
                onChange={this.handleInputChange}
                required
              ></textarea>
              <label className={this.state.form.notas.isEmpty?"contactus__label contactus__label-valid":"contactus__label"}>Comentarios</label>
            </div>
          </form>
          <Reaptcha className="contactus__recaptcha" sitekey="6Ld5c2YUAAAAAC6y_aK9bE43TOB552ZPcCRG-Kzc" onVerify={this.onVerify}/>
          <button onClick={this.sendEmail}
            className={this.state.isFormValid?"contactus__submit-button contactus__submit-button-enabled":
          "contactus__submit-button contactus__submit-button-disabled"}

                    >Submit</button>
                </div>
                <div className={this.showLoading()?"contactus__loading":"contactus__display-none"}>
                  <img className="header__title-flower" alt="cargando" src={loadingImg}></img>
                </div>
                <div className={this.showDone()?"contactus__done":"contactus__display-none"}>
                  <h3 className="contactus__thankyou">Muchas gracias por contactarme!</h3>
                  <div className="contactus__thankyousub">Tu correo se ha enviado exitosamente y me pondré en contacto pronto. </div>
                </div>
              </div>
    )

  }
}

export default ContactUs
