import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import ContactUs from "../components/contactus/contactus"

const Contacto = () => (
  <Layout>
    <SEO lang="es" description="Contacto" title="Contacto" />
    <ContactUs></ContactUs>
  </Layout>
)

export default Contacto
